import { DatePeriod } from 'adp-panel/api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import { DateRangeType } from 'adp-panel/components/DeviceUsageMonitoring/UsageMonitoringGraph';
import {
  InitialFilters,
  period_options
} from 'adp-panel/components/DeviceUsageMonitoring/useGraphsFormState';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const useDateRangeFilter = (initialFilters: InitialFilters) => {
  const [selectedPeriod, setSelectedPeriod] = useState<DatePeriod>('today');

  const [period, setPeriod] = useState(
    initialFilters.period
      ? period_options.find(({ id }) => id === initialFilters.period) || period_options[0]
      : period_options[0]
  );

  const [dateRange, setDateRange] = useState<DateRangeType>(
    initialFilters.dateRange ?? { start: new Date(), end: new Date() }
  );

  useEffect(() => {
    switch (period.id) {
      case 'today':
        setDateRange({ end: new Date(), start: new Date() });
        break;
      case 'week':
        setDateRange({ end: new Date(), start: dayjs().subtract(7, 'day').toDate() });
        break;
      case 'month':
        setDateRange({ end: new Date(), start: dayjs().subtract(30, 'day').toDate() });
        break;
    }
  }, [period]);

  return {
    selectedPeriod,
    period,
    dateRange,
    setPeriod,
    setDateRange,
    setSelectedPeriod
  };
};

export default useDateRangeFilter;
