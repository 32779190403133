import { CustomGripEntry } from 'configurator/api/device/device.types';
import { Grips, gripsGroupsOptionsWithTranslationKeyMap } from './definesLocal';

export const EXCLUDED_GRIPS = [Grips.kGripCamera, Grips.kGripTypeUnknown] as const;
type ExcludedGrips = (typeof EXCLUDED_GRIPS)[number];

type ValidGripId = Exclude<Grips, ExcludedGrips>;

export const getStandardGripIds = (): ValidGripId[] => {
  const standardGripIds = Array.from(gripsGroupsOptionsWithTranslationKeyMap.keys());
  return standardGripIds.filter(
    (gripId): gripId is ValidGripId => !EXCLUDED_GRIPS.includes(gripId as ExcludedGrips)
  );
};

export const getAllGripIds = (customGrips: CustomGripEntry[] = []): number[] => {
  const standardGrips = getStandardGripIds();
  const customGripIds = customGrips.map((grip) => grip.grip_number);

  return [...new Set([...standardGrips, ...customGripIds])];
};
