import {
  DumRawFilesEntry,
  DumRawFilesQueryParams
} from 'adp-panel/api/dumRawFiles/dumRawFiles.types';
import { ApiDUMClient, withCredentials } from 'adp-panel/api/utils/apiClient';
import qs from 'qs';
import { PaginatedResponse } from 'types';

export const GET_RAW_DUM_FILES = 'api/raw-files/{deviceId}';

export const getDumRawFiles = (
  params: DumRawFilesQueryParams
): Promise<PaginatedResponse<DumRawFilesEntry>> => {
  return ApiDUMClient.get(
    `${GET_RAW_DUM_FILES.replace('{deviceId}', `${params.deviceId}`)}?${qs.stringify(params)}`,
    withCredentials
  ).then((res: any) => res.data);
};
