import { useState, useMemo } from 'react';
import {
  EmgPeakChartQueryParams,
  UsageMonitoryQueryParams,
  VelocityLevelChartQueryParams
} from 'adp-panel/api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import { GraphFilters } from 'adp-panel/components/DeviceUsageMonitoring/UsageMonitoringGraph';
import dayjs from 'dayjs';
import { useDeviceUsageContext } from './DeviceUsageContext';

export const formatData = (date: Date | undefined) => {
  return dayjs(date).format('YYYY-MM-DD');
};

const transformGrips = (
  grip: string | number,
  deviceId: number,
  getDeviceGripIds: (id: number) => number[]
) => {
  if (grip === 'all') {
    return getDeviceGripIds(deviceId);
  }
  return [Number(grip)];
};

export const filterParser = {
  default: (
    filters: GraphFilters,
    previousFilters: UsageMonitoryQueryParams,
    context: ReturnType<typeof useDeviceUsageContext>
  ): UsageMonitoryQueryParams => {
    if (!filters.device) {
      return previousFilters;
    }
    return {
      ...previousFilters,
      deviceId: filters.device.id,
      date_from: formatData(filters?.dateRange?.start),
      date_to: filters?.dateRange?.end ? formatData(filters?.dateRange?.end) : undefined
    };
  },
  emgPeak: (
    filters: GraphFilters,
    previousFilters: EmgPeakChartQueryParams,
    context: ReturnType<typeof useDeviceUsageContext>
  ): EmgPeakChartQueryParams => {
    if (!filters.device) {
      return previousFilters;
    }
    return {
      ...previousFilters,
      deviceId: filters.device.id,
      date_from: formatData(filters?.dateRange?.start),
      date_to: filters?.dateRange?.end ? formatData(filters?.dateRange?.end) : undefined
    };
  },
  hourly: (
    filters: GraphFilters,
    previousFilters: UsageMonitoryQueryParams,
    context: ReturnType<typeof useDeviceUsageContext>
  ): UsageMonitoryQueryParams => {
    const { grip, device } = filters;
    if (!device?.id || grip === undefined) {
      return previousFilters;
    }

    const deviceId = device.id;
    const transformedGrip = transformGrips(grip, deviceId, context.getDeviceGripIds);

    return {
      ...previousFilters,
      deviceId,
      ...(transformedGrip && transformedGrip.length > 0 && { grips: transformedGrip }),
      date_from: formatData(filters?.dateRange?.start),
      date_to: filters?.dateRange?.end ? formatData(filters?.dateRange?.end) : undefined
    };
  },
  velocity: (
    filters: GraphFilters,
    previousFilters: VelocityLevelChartQueryParams,
    context: ReturnType<typeof useDeviceUsageContext>
  ): VelocityLevelChartQueryParams => {
    const { grip, device } = filters;

    if (!device || grip === undefined) {
      return previousFilters;
    }

    const transformedGrip = transformGrips(grip, device.id, () => []);

    return {
      ...previousFilters,
      deviceId: device.id,
      ...(transformedGrip && transformedGrip.length > 0 && { grips: transformedGrip }),
      date_from: formatData(filters?.dateRange?.start),
      date_to: filters?.dateRange?.end ? formatData(filters?.dateRange?.end) : undefined
    };
  }
};

interface BaseQueryParams {
  deviceId?: number;
}

const useDeviceUsageTab = <QueryType extends BaseQueryParams>(
  fetchFunction: Function,
  params = {} as QueryType,
  parser: (
    _filters: GraphFilters,
    _params: QueryType,
    _context: ReturnType<typeof useDeviceUsageContext>
  ) => any = filterParser.default
) => {
  const context = useDeviceUsageContext();
  const finalParser = parser ?? filterParser.default;
  const [localParams, setParams] = useState<QueryType>(params);
  const { result: chartData = [], isLoading } = fetchFunction(localParams, !!localParams.deviceId);

  const handleFilterChange = (filters: GraphFilters) => {
    setParams(finalParser(filters, localParams, context));
  };

  return {
    handleFilterChange,
    chartData,
    isLoading
  };
};

export default useDeviceUsageTab;
