import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { NotificationFactory } from 'lib/NotificationFactory';
import regionAPI from 'adp-panel/api/utils/apiRegionClient';
import { setAPIDUMUrl, setAPIUrl } from 'adp-panel/api/utils/apiClient';
import i18next from 'i18next';

interface RegionResponse {
  endpoint: string;
  endpoints: {
    api: string;
    dum: string;
  };
  email: string;
  region: 'us' | 'eu';
}

export type RegionList = {
  [key in 'us' | 'eu']: {
    endpoints: {
      api: string;
      dum: string;
    };
  };
};

export const REGIONS_KEY = 'regions';

export const useRegions = () => {
  const { data, isLoading, isError } = useQuery([REGIONS_KEY], async () => {
    const response = await regionAPI.get<RegionList[]>(`regions`);
    return response.data;
  });

  return {
    result: data ? data : [],
    isLoading,
    isError
  };
};

export const useUserRegion = () => {
  const [isLoading, setIsLoading] = useState(false);

  const checkRegion = async (email: string): Promise<RegionResponse | null> => {
    try {
      const response = await regionAPI.get<RegionResponse>(`get-region`, {
        params: { email }
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const getUserRegion = async (email: string): Promise<boolean> => {
    setIsLoading(true);
    try {
      const response = await regionAPI.get(`get-region`, {
        params: { email }
      });

      setAPIUrl(response.data.endpoints.api);
      setAPIDUMUrl(response.data.endpoints.dum);
      localStorage.setItem('endpoint', response.data.endpoints.api);
      localStorage.setItem('dumEndpoint', response.data.endpoints.dum);
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      NotificationFactory.errorNotification(
        i18next.t(
          'common:notification.login.user_in_region_error',
          "We're sorry, but the email you entered is not associated with an account. Please check your email and try again."
        )
      );
      return false;
    }
  };

  return {
    getUserRegion,
    checkRegion,
    isLoading
  };
};
