import { updateDevice } from 'adp-panel/api/devices/devices';
import { useState } from 'react';
import { useDevicesWithDumps } from '../../../hooks/api/useDevicesWithDumps';
import { DeviceExtendOptions, DevicesQueryParams } from '../../../api/devices/device.types';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { NotificationFactory } from 'lib/NotificationFactory';
import { DEVICES_WITH_DUMPS } from 'adp-panel/hooks/api/useDevicesWithDumps';

export const usePatientDevices = (patientId: string) => {
  const { t } = useTranslation();
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const queryClient = useQueryClient();

  const queryParamsDevices: DevicesQueryParams = {
    amputee: patientId,
    page: paginationModel.page + 1,
    perpage: paginationModel.pageSize,
    extend: [DeviceExtendOptions.model, DeviceExtendOptions.amputee]
  };

  const {
    result: devices,
    total: totalDevices,
    isLoading: isLoadingDevices
  } = useDevicesWithDumps(queryParamsDevices);

  const handleUnassignDevice = async (deviceId: number) => {
    try {
      await updateDevice({
        data: { amputee_id: null },
        deviceId: deviceId.toString(),
        successMessage: t(
          'notifications:device_unassigned_patient_success',
          'Device has been unassigned from patient'
        )
      });
      NotificationFactory.successNotification(
        t(
          'notifications:device_unassigned_patient_success',
          'Device has been unassigned from patient'
        )
      );
      queryClient.invalidateQueries({
        queryKey: [DEVICES_WITH_DUMPS]
      });
    } catch (err) {
      console.log(err);
    }
  };

  return {
    devices: devices || [],
    totalDevices,
    isLoadingDevices,
    selectedDevice,
    setSelectedDevice,
    paginationModel,
    setPaginationModel,
    handleUnassignDevice
  };
};
