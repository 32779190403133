/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Commands } from 'bluetooth/Bluetooth/Defines';
import { ACK_FIRMWARE } from 'configurator/consts/consts';
import { numericalFirmwareVersion, timeoutCommandLite } from 'configurator/utils/funcs';
import platform from 'platform';
import { BluetoothReader, CreateFrame } from '../../bluetooth/Bluetooth/Bluetooth';
import { postNewTelemetryEnabled } from './bluetoothFunctions';

const blReader = new BluetoothReader();

class BluetoothWebController {
  static _instance: any;
  server: any = null;
  connected: boolean = false;
  telemetryEnabled: boolean = false;
  device: any;
  controller: any;
  isSupportingAck: boolean = false;
  characteristicRead: any;
  characteristicWrite: any;
  continueReading: boolean = true;
  commandDataTransformed: {} = {};
  readerLoop: any;

  constructor() {
    if (BluetoothWebController._instance) {
      return BluetoothWebController._instance;
    }
    BluetoothWebController._instance = this;
    this.connected = false;
  }

  async getPairing(bluetoothId: string | null) {
    try {
      const newNavigator: any = window.navigator;
      if (!platform.os?.family) return false;

      if (!this.device || platform.os.family === 'OS X') {
        this.device = await newNavigator.bluetooth.requestDevice({
          filters: [
            bluetoothId && platform.os.family !== 'OS X'
              ? { name: bluetoothId }
              : { services: ['6e400001-c352-11e5-953d-0002a5d5c51b'] }
          ],
          optionalServices: [
            '6e400001-c352-11e5-953d-0002a5d5c51b',
            '6e400002-c352-11e5-953d-0002a5d5c51b',
            '6e400003-c352-11e5-953d-0002a5d5c51b',
            'device_information'
          ]
        });
      }

      this.server = await this.device.gatt.connect();
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async getBluetoothId() {
    if (this.device) return this.device.name;
    return false;
  }

  async getSerial() {
    try {
      if (!this.server) return false;
      const service = await this.server.getPrimaryService('device_information');
      const characteristic = await service.getCharacteristic('model_number_string');
      const value = await characteristic.readValue();
      const decoder = new TextDecoder('utf-8');
      const serialNumber = decoder.decode(value.buffer);
      return serialNumber.slice(5, serialNumber.length);
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async initiateBluetooth(): Promise<boolean> {
    try {
      this.continueReading = true;

      if (!platform.os?.family) return false;
      if (!this.server) return false;
      const service = await this.server.getPrimaryService('6e400001-c352-11e5-953d-0002a5d5c51b');
      this.characteristicWrite = await service.getCharacteristic(
        '6e400002-c352-11e5-953d-0002a5d5c51b'
      );
      this.characteristicRead = await service.getCharacteristic(
        '6e400003-c352-11e5-953d-0002a5d5c51b'
      );
      this.connected = true;
      this.readerLoop = await this.readWeb();
      this.controller = new AbortController();
      this.#checkAckSupport();
      return true;
    } catch (err) {
      this.disconnectBluetooth();
      if (err instanceof DOMException) {
        if (err.name === 'NotFoundError') {
          return false;
        }
      }
      console.error('There was an error opening the serial port', err);
      return false;
    }
  }

  async disconnectBluetooth() {
    await this.telemetryOff();
    if (this.controller) {
      this.controller.abort();
    }
    this.continueReading = false;
    if (this.device) {
      await this.device.gatt.disconnect();
    }
    this.characteristicWrite = null;
    this.characteristicRead = null;
    blReader.mIncomingBuffer = [];
    blReader.mCRC = [];
    blReader.frameIterator = 2;
    console.log('Device disconnect');
    this.connected = false;
    return true;
  }

  async readWeb() {
    if (!this.connected) return;
    this.characteristicRead = await this.characteristicRead.startNotifications();

    this.controller = new AbortController();

    this.characteristicRead.addEventListener(
      'characteristicvaluechanged',
      (e) => handleValueChanged(Array.from(new Uint8Array(e.target.value.buffer))),
      { signal: this.controller.signal }
    );

    console.log('READWEB', this.characteristicRead);

    const handleValueChanged = (value) => {
      if (this.continueReading) {
        try {
          const valueBleExtracted = value.slice(1);
          const commandData = blReader.receiveDataFromSerialPort(valueBleExtracted);
          if (commandData) {
            commandData.forEach((element) => {
              this.commandDataTransformed = [element];

              let eventData;
              if (element.command === Commands.kReturnAck) {
                eventData = `received${element.command}:${element.payload[1]}`;
              } else {
                eventData = `received${element.command}`;
              }

              const event = new CustomEvent(eventData, {
                detail: this.commandDataTransformed
              });
              window.dispatchEvent(event);
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
  }

  async writeWeb(command, commandData): Promise<{ status: boolean; crc32: any } | undefined> {
    const limit = 20;
    let tries = 0;
    const sendFrame = async (data) => {
      try {
        if (!this.connected) return;
        tries += 1;
        console.log('DATA SEND IN PROGRESS', data, tries);
        if (tries < limit) await this.characteristicWrite.writeValueWithoutResponse(data);
        console.log('DATA SEND SUCCESSFUL', data);
      } catch (e) {
        console.log(`%c${e}`, 'background: #F00; color: #FFF');
        console.log('DATA SEND FAILED, TRYING AGAIN', data);
        sendFrame(data);
      }
    };

    try {
      const { frame, crc32 } = CreateFrame(command, commandData);
      const data = new Uint8Array([0x01, ...frame]);
      sendFrame(data);
      return { status: true, crc32 };
    } catch (err) {
      console.log(err);
    }
  }

  async queryResponseCommand(
    command,
    commandData,
    commandAwaited,
    listenMode = false
  ): Promise<any> {
    console.log('QUERY', command, 'AWAITING', commandAwaited);
    if (!this.connected) return;
    if (!listenMode) await this.writeWeb(command, commandData);
    return new Promise((resolve) => {
      window.addEventListener(
        `received${commandAwaited}`,
        (data: any) => {
          console.log('RESOLVED', commandAwaited, data.detail);
          resolve(data.detail);
        },
        {
          once: true
        }
      );
    });
  }

  async queryAcknowledge(command, commandData): Promise<any> {
    if (!this.connected) return;

    const result = await this.writeWeb(command, commandData);

    if (!this.isSupportingAck) return;

    if (!result) {
      return;
    }

    const { crc32 } = result;

    console.log(`WAITING ACKNOWLEDGE ${crc32}`, command);
    return new Promise((resolve) => {
      window.addEventListener(
        `received${Commands.kReturnAck}:${crc32}`,
        (data: any) => {
          console.log('ACKNOWLEDGED', command, data.detail);
          resolve(data.detail);
        },
        {
          once: true
        }
      );
    });
  }

  async telemetryOn() {
    try {
      if (!this.connected) return;
      this.telemetryEnabled = true;
      if (this.isSupportingAck) {
        await postNewTelemetryEnabled(true);
        return true;
      }
      await this.writeWeb(Commands.kStartOrStopTransmittingTelemetryData, [1]);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async telemetryOff() {
    try {
      if (!this.connected) return;
      this.telemetryEnabled = false;
      if (this.isSupportingAck) {
        await postNewTelemetryEnabled(false);
        return true;
      }
      await this.writeWeb(Commands.kStartOrStopTransmittingTelemetryData, [0]);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  forgetDevice() {
    this.device = null;
  }

  async #checkAckSupport() {
    if (!this.connected) return;

    const [{ payload: firmwareVersion }] = (await timeoutCommandLite(() =>
      this.queryResponseCommand(
        Commands.kQueryFirmwareAndAppVersions,
        [],
        Commands.kFirmwareAndAppVersions
      )
    )) || [{ payload: false }];

    if (!firmwareVersion) return;

    const currentVersion = firmwareVersion
      .slice(0, 8)
      .map((item) => String.fromCharCode(item))
      .join('');

    const firmwareVersionCurrentParsed = numericalFirmwareVersion(currentVersion);
    if (firmwareVersionCurrentParsed >= numericalFirmwareVersion(ACK_FIRMWARE)) {
      this.isSupportingAck = true;
      return true;
    }
    this.isSupportingAck = false;
    return true;
  }
}

export default BluetoothWebController;
