import { Chip, ChipOwnProps } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import React, { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import CustomIcon from 'components/CustomIcon/CustomIcon';
import {
  DescriptionPanel,
  DescriptionTitle,
  DescriptionText,
  InterpretationSection,
  SectionTitle,
  IssueContainer,
  InfoBox,
  InfoContent,
  InfoTitle,
  InfoText,
  TitleWithIcon,
  IconWrapper,
  InfoTitleWithIcon,
  CloseButtonWrapper,
  InfoBoxesContainer
} from './styled';
import { getChartDescription } from './descriptions';
import { ChartType } from './descriptions/types';

interface GraphDescriptionProps {
  onClose: () => void;
  header: string;
  chartType: ChartType;
}

interface CustomChip extends ChipOwnProps {
  sx?: any;
  status: 'good' | 'bad' | 'neutral';
}

const CustomChip = ({ sx, ...props }: CustomChip & ComponentPropsWithoutRef<'div'>) => {
  const style = {
    ...(props.status === 'good' && {
      border: '1px solid #ABEFC6',
      backgroundColor: '#ECFDF3',
      color: '#067647'
    }),
    ...(props.status === 'bad' && {
      border: '1px solid #FECDCA',
      backgroundColor: '#FEF3F2',
      color: '#B42318'
    }),
    ...(props.status === 'neutral' && {
      border: '1px solid #E4E7EC',
      backgroundColor: '#F9FAFB',
      color: '#344054'
    }),
    borderRadius: '16px',
    fontWeight: 500,
    fontSize: '12px',
    padding: '8px 2px',
    height: '22px',
    marginBottom: '10px',
    ...sx
  };

  return <Chip sx={{ ...style, ...sx }} {...props} />;
};

export const GraphDescription: React.FC<GraphDescriptionProps> = ({
  onClose,
  header,
  chartType
}) => {
  const { t } = useTranslation();
  const chartInfo = getChartDescription(chartType);

  return (
    <DescriptionPanel>
      <DescriptionTitle>{`${header} - Description`}</DescriptionTitle>
      <DescriptionText>{t(chartInfo.description)}</DescriptionText>

      {chartInfo.sections.length > 0 && (
        <SectionTitle>
          {t('common:component.graphs_description.title', 'Interpretation and Possible Issues')}
        </SectionTitle>
      )}

      {chartInfo.sections.map((section, sectionIndex) => (
        <InterpretationSection key={sectionIndex}>
          <TitleWithIcon>
            {section.icon && (
              <IconWrapper variant={section.iconVariant}>
                <CustomIcon name={section.icon} />
              </IconWrapper>
            )}
            <SectionTitle>{t(section.title)}</SectionTitle>
          </TitleWithIcon>

          <IssueContainer>
            <InfoBoxesContainer
              columns={
                section.examples
                  ? section.content.length + section.examples.length
                  : section.content.length
              }>
              {section.content.map((item, itemIndex) => (
                <InfoBox isText key={`example-${itemIndex}`}>
                  <InfoContent>
                    <InfoTitleWithIcon>
                      {item.icon && (
                        <IconWrapper variant={item.iconVariant}>
                          <CustomIcon name={item.icon} />
                        </IconWrapper>
                      )}
                      <InfoTitle>{t(item.title)}</InfoTitle>
                    </InfoTitleWithIcon>
                    <InfoText>{t(item.content)}</InfoText>
                  </InfoContent>
                </InfoBox>
              ))}
              {section.examples &&
                section.examples.length > 0 &&
                section.examples.map((example, exampleIndex) => (
                  <InfoBox key={exampleIndex}>
                    <InfoTitle>
                      <CustomChip label={t(example.label)} status={example.status} />
                    </InfoTitle>
                    <img src={example.image} alt={t(example.label)} />
                  </InfoBox>
                ))}
            </InfoBoxesContainer>
          </IssueContainer>
        </InterpretationSection>
      ))}

      <CloseButtonWrapper>
        <CustomButton color='light' onClick={onClose}>
          Close
        </CustomButton>
      </CloseButtonWrapper>
    </DescriptionPanel>
  );
};
