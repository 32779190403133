import styled from 'styled-components';

export const TwoColumnGraphLayout = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: space-between;
`;

export const OneColumnGraphLayout = styled.div`
  width: 100%;
  height: 500px;
`;

export const CommonFilterWrapper = styled.div`
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 16px;
  row-gap: 12px;
`;
