import { CircularProgress } from '@mui/material';
import { getAllGripIds } from 'adp-panel/utils/grips';
import { getCustomGrips } from 'configurator/api/device/device';
import { CustomGripEntry } from 'configurator/api/device/device.types';
import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { useQueries } from '@tanstack/react-query';
import { DeviceEntry } from 'adp-panel/api/devices/device.types';

type DeviceUsageContextType = {
  deviceGrips: Record<string, CustomGripEntry[]>;
  isLoadingDevices: boolean;
  getDeviceGripIds: (deviceId: number) => number[];
};

const DeviceUsageContext = createContext<DeviceUsageContextType | null>(null);

export const DeviceUsageProvider = ({
  children,
  devices
}: {
  children: React.ReactNode;
  devices: DeviceEntry[];
}) => {
  const deviceQueries = useQueries({
    queries: devices.map((device) => ({
      queryKey: ['customGrips', device.id],
      queryFn: () => getCustomGrips({ deviceId: device.id }),
      enabled: !!device.id
    }))
  });

  const isLoading = deviceQueries.some((query) => query.isLoading || query.isFetching);

  const deviceGrips = useMemo(() => {
    return devices.reduce(
      (acc, device, index) => {
        if (deviceQueries[index]?.data) {
          acc[device.id] = deviceQueries[index].data?.items || [];
        }
        return acc;
      },
      {} as Record<string, CustomGripEntry[]>
    );
  }, [devices, deviceQueries]);

  const getDeviceGripIds = useCallback(
    (deviceId: number) => {
      const customGrips = deviceGrips[deviceId] || [];
      return getAllGripIds(customGrips);
    },
    [deviceGrips]
  );

  const value = useMemo(
    () => ({
      deviceGrips,
      isLoadingDevices: isLoading,
      getDeviceGripIds
    }),
    [deviceGrips, isLoading, getDeviceGripIds]
  );

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'inherit'
        }}>
        <CircularProgress />
      </div>
    );
  }

  return <DeviceUsageContext.Provider value={value}>{children}</DeviceUsageContext.Provider>;
};

export const useDeviceUsageContext = () => {
  const context = useContext(DeviceUsageContext);
  if (!context) {
    throw new Error('useDeviceUsageContext must be used within a DeviceUsageProvider');
  }
  return context;
};
