import { useMemo } from 'react';
import { useDeviceUsageContext } from 'adp-panel/pages/DeviceUsage/DeviceUsageContext';
import { Grips, gripsGroupsOptionsWithTranslationKeyMap } from 'adp-panel/utils/definesLocal';
import { useTranslation } from 'react-i18next';

export const useGripLabel = (deviceId: number) => {
  const { t } = useTranslation();
  const { deviceGrips } = useDeviceUsageContext();

  const customGripsMap = useMemo(() => {
    const deviceCustomGrips = deviceGrips[deviceId] || [];
    return new Map(deviceCustomGrips.map((grip) => [grip.grip_number, grip.name]));
  }, [deviceGrips, deviceId]);

  const getGripLabel = (gripId: number): string => {
    const customGripLabel = customGripsMap.get(gripId);
    if (customGripLabel) {
      return customGripLabel;
    }

    const standardGripLabel = gripsGroupsOptionsWithTranslationKeyMap.get(gripId as Grips);
    if (standardGripLabel) {
      return t(standardGripLabel);
    }

    return t('common:grips.unknown', 'Unknown Grip');
  };

  return {
    getGripLabel,
    hasCustomGrips: customGripsMap.size > 0
  };
};
