import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { CommonFiltersProvider } from 'adp-panel/pages/DeviceUsage/CommonFiltersContext';
import { getTableSize } from '../../utils/tableHelpers';
import { ActionButtonsWrapper } from '../../components/Table/styles';
import { MODALS } from 'configurator/views/Modals';
import {
  useUserGoalDelete,
  useUserGoalProgress,
  useUserGoalsInfinite,
  useUserGoalUpdate
} from '../../hooks/api/useGoals';
import { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import {
  ExerciseGoalsGraph,
  GripGoalsCountGraph,
  SwitchesGoalsCountGraph
} from 'adp-panel/components/DeviceUsageMonitoring/Graphs';
import useDeviceUsageTab, { filterParser } from 'adp-panel/pages/DeviceUsage/useDeviceUsageTab';
import { PeriodEnum } from 'adp-panel/api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import { useDevicesList } from 'adp-panel/hooks/api/useDevices';
import {
  DeviceExtendOptions,
  DevicesQueryParams,
  DeviceEntry
} from 'adp-panel/api/devices/device.types';
import UsageMonitoringGraph from 'adp-panel/components/DeviceUsageMonitoring/UsageMonitoringGraph';
import { Checkbox } from '@progress/kendo-react-inputs';
import {
  useDeviceUsageChartData,
  useDeviceUsageGripsCountData
} from 'adp-panel/hooks/api/useDeviceUseageMonitoring';
import {
  colors,
  formatGoal,
  getGripsInGoal,
  getSwitchesInGoal,
  GoalsStatus,
  goalsStatusMap,
  groupGoalsValues,
  TransformedExercise,
  transformExercises
} from './utils';
import { CheckboxesWrapper } from './styled';
import { useModal } from 'adp-panel/hooks/api/useModal';
import ConfirmDeleteModal, {
  ModalMessageDelete
} from 'adp-panel/components/Modals/ConfirmDeleteModal';
import { Loader } from '@progress/kendo-react-indicators';
import useElementSize from 'adp-panel/hooks/useElementSize';
import { NotificationFactory } from 'lib/NotificationFactory';
import { GoalConditionType, GoalEntryPayload } from 'adp-panel/api/goals/goals.types';
import { timeFrequenciesMap, timePeriodsMap } from 'adp-panel/utils/definesLocal';
import { useUiStore } from 'configurator/reducers/uiStore';
import Chip from '@mui/material/Chip';
import CustomButton from 'components/Button/CustomButton';
import { ReactComponent as LockSVG } from 'assets/lock3.svg';
import { ReactComponent as LockOpenSVG } from 'assets/lock-open.svg';
import { ReactComponent as TrashSvg } from 'assets/trash_black.svg';
import { ReactComponent as LeadingSwapSvg } from 'assets/leading_swap.svg';
import { ReactComponent as LineChartUpSvg } from 'assets/line_chart_up.svg';
import { useTranslation } from 'react-i18next';

const colorScale = colors;
const GOAL_GRAPH_HEIGHT = '600px';

const DetailComponent = ({ goal, devices }: { goal: GoalEntryPayload; devices: DeviceEntry[] }) => {
  const gripsGoals = getGripsInGoal(goal);
  const switchesGoal = getSwitchesInGoal(goal);
  const chartRef = useRef(null);
  const chartSize = useElementSize(chartRef);

  const { t } = useTranslation('goals');

  const {
    chartData: gripsPerformedData,
    handleFilterChange: handleGripsGoalFilter,
    isLoading: isLoadingGripsPerformedData
  } = useDeviceUsageTab(
    useDeviceUsageGripsCountData,
    { group: timeFrequenciesMap.get(gripsGoals?.gripsFrequency) },
    filterParser.default
  );
  const {
    chartData: gripsSwitchesData,
    handleFilterChange: handleSwitchesGoalFilter,
    isLoading: isLoadingGripsSwitchesData
  } = useDeviceUsageTab(
    useDeviceUsageChartData,
    { group: timeFrequenciesMap.get(switchesGoal?.switchesFrequency) },
    filterParser.default
  );
  const [selectedGrips, setSelectedGrips] = useState<any>();

  useEffect(() => {
    if (gripsGoals?.gripsNames) {
      setSelectedGrips(
        gripsGoals?.gripsNames.map((gripName, index) => ({
          name: gripName,
          value: true,
          color: colorScale[index]
        }))
      );
    }
  }, [JSON.stringify(gripsGoals)]);

  const goalStartDate = new Date(goal.start_date);
  const goalEndDate = new Date(goal.end_date);
  const gripsPerformedGrouped = groupGoalsValues(
    gripsPerformedData,
    gripsGoals!.gripsFrequency,
    GoalConditionType.grip,
    {
      goalStartDate,
      goalEndDate
    },
    // @ts-ignore
    gripsGoals.gripsNames
  );

  const switchesPerformedGrouped = groupGoalsValues(
    gripsSwitchesData,
    switchesGoal!.switchesFrequency,
    GoalConditionType.switch,
    {
      goalStartDate,
      goalEndDate
    },
    // @ts-ignore
    gripsGoals.gripsNames
  );

  const handleCheckboxChange = (gripToggle: any) => {
    setSelectedGrips((prev: any) =>
      prev.map((_gripToggle: any) => {
        if (_gripToggle.name === gripToggle.name)
          return { ..._gripToggle, value: !_gripToggle.value };

        return _gripToggle;
      })
    );
  };

  const filteredGripsKeys: any = () => {
    if (!selectedGrips) return [];

    return {
      keys: gripsGoals?.gripsNames.filter((name: any) => {
        const obj: any = selectedGrips.find((o: any) => o.name === name);
        return obj && obj.value;
      }),
      markers: gripsGoals?.gripsInvolved.filter((grip: any) => {
        const obj: any = selectedGrips.find((o: any) => o.name === grip.name);
        return obj && obj.value;
      }),
      getColor: (datumName: any) => {
        const mapping = selectedGrips.find((item: any) => item.name === datumName);
        return mapping.color;
      }
    };
  };

  const renderGripsGraph = useCallback(
    () => (
      <GripGoalsCountGraph
        legend={`Date (${timePeriodsMap.get(gripsGoals?.gripsFrequency)})`}
        data={gripsPerformedGrouped ?? []}
        markers={
          filteredGripsKeys()?.markers
            ? filteredGripsKeys()?.markers.map((grip: any) => ({
                axis: 'y',
                value: grip.grips_count,
                lineStyle: {
                  stroke: selectedGrips.find((_grip: any) => _grip.name === grip.name).color,
                  strokeWidth: 2,
                  strokeDasharray: '10'
                },
                legend: 'Goal',
                legendOrientation: 'horizontal'
              }))
            : []
        }
        keys={filteredGripsKeys()?.keys}
        colors={(datum: any) => filteredGripsKeys()?.getColor(datum.id)}
        size={chartSize.width}
      />
    ),
    [gripsGoals, gripsPerformedGrouped, selectedGrips, chartSize]
  );

  const renderSwitchesGraph = useCallback(
    () => (
      <SwitchesGoalsCountGraph
        legend={`Date (${timePeriodsMap.get(switchesGoal?.switchesFrequency)})`}
        data={switchesPerformedGrouped ?? []}
        keys={['Switches']}
        markers={
          switchesGoal
            ? [
                {
                  axis: 'y',
                  value: switchesGoal.switchesCount,
                  lineStyle: {
                    stroke: colorScale[13],
                    strokeWidth: 2,
                    strokeDasharray: '10'
                  },
                  legend: 'Goal',
                  legendOrientation: 'horizontal'
                }
              ]
            : []
        }
        colors={colorScale}
        size={chartSize.width}
      />
    ),
    [switchesGoal, colorScale, switchesPerformedGrouped, chartSize]
  );

  return (
    <div style={{ height: GOAL_GRAPH_HEIGHT, overflowY: 'scroll' }} ref={chartRef}>
      <CommonFiltersProvider value={{}}>
        <UsageMonitoringGraph
          totalCounter={false}
          header={t('goals:usage_tab.grip.grips_progress', 'Grips performed progress')}
          devices={devices ?? []}
          initialFilters={{
            period: PeriodEnum.day,
            dateRange: {
              start: goalStartDate,
              end: goalEndDate
            }
          }}
          additionalFilterProps={{
            dateRange: {
              min: goalStartDate,
              max: goalEndDate,
              disabled: true
            }
          }}
          extraFilters={
            selectedGrips && (
              <CheckboxesWrapper>
                {selectedGrips.map((gripToggle: any) => (
                  <Checkbox
                    value={gripToggle.value}
                    key={gripToggle.name}
                    label={gripToggle.name}
                    onChange={() => handleCheckboxChange(gripToggle)}
                  />
                ))}
              </CheckboxesWrapper>
            )
          }
          onFilterChange={handleGripsGoalFilter}
          deviceFilter
          dateRangeFilter
          isLoading={isLoadingGripsPerformedData}
          showExplanation={false}
          graphHeight={GOAL_GRAPH_HEIGHT}
          graphDataSource={[]}
          GraphComponent={renderGripsGraph}
          chartType='grip-performed'
        />
        <UsageMonitoringGraph
          header={t('goals:usage_tab.grip.switches_progress', 'Switches performed progress')}
          devices={devices ?? []}
          showExplanation={false}
          isLoading={isLoadingGripsSwitchesData}
          totalCounter={false}
          initialFilters={{
            instance: 'Instance',
            period: PeriodEnum.day,
            dateRange: { start: goalStartDate, end: goalEndDate }
          }}
          additionalFilterProps={{
            dateRange: {
              min: goalStartDate,
              max: goalEndDate,
              disabled: true
            }
          }}
          onFilterChange={handleSwitchesGoalFilter}
          deviceFilter
          dateRangeFilter
          graphHeight={GOAL_GRAPH_HEIGHT}
          graphDataSource={[]}
          GraphComponent={renderSwitchesGraph}
          chartType='switches-performed'
        />
        <ExerciseGraphComponent goal={goal} size={chartSize} />
      </CommonFiltersProvider>
    </div>
  );
};

const CustomTooltip = ({ point }: any) => {
  const { t } = useTranslation('goals');
  return (
    <div
      style={{
        background: '#626C84',
        padding: '9px 12px',
        borderRadius: '4px',
        fontSize: '12px',
        color: 'white'
      }}>
      {`${t('goals:usage_tab.tooltip.failed_attempts', 'Failed attempts')}: ${
        point.data.unsuccessful
      }`}
    </div>
  );
};

const CustomPoint = ({ size, borderWidth, borderColor, datum }: any) => {
  return (
    <circle
      r={size}
      fill={datum.unsuccessful > 0 ? colorScale[13] : colorScale[6]}
      strokeWidth={borderWidth}
      stroke={borderColor}
    />
  );
};

const ExerciseGraphComponent = ({ goal, size }: { goal: GoalEntryPayload; size: any }) => {
  const { result: exercises } = useUserGoalProgress({
    goalId: goal.id,
    transformData: (data) => transformExercises(data, goal)
  });

  const [selectedExercise, setSelectedExercise] = useState<TransformedExercise>();

  const renderExercisesGraph = useCallback(
    () => (
      <ExerciseGoalsGraph
        useMesh={true}
        tooltip={CustomTooltip}
        pointSymbol={CustomPoint}
        markers={
          selectedExercise?.exercise_count
            ? [
                {
                  axis: 'y',
                  value: selectedExercise?.exercise_count,
                  lineStyle: {
                    stroke: colorScale[6],
                    strokeWidth: 2,
                    strokeDasharray: '10'
                  },
                  legend: 'Goal',
                  legendOrientation: 'horizontal'
                }
              ]
            : []
        }
        colors={[colorScale[6]]}
        legend={`Time (${timePeriodsMap.get(selectedExercise?.exercise_frequency)})`}
        size={size.width}
        data={[
          {
            id: 'Successful attempts',
            data: selectedExercise?.attempts ? selectedExercise.attempts : []
          }
        ]}
      />
    ),
    [selectedExercise, colorScale, size]
  );
  const { t } = useTranslation('goals');

  // @ts-ignore
  if (exercises?.length === 0) {
    return null;
  }

  return (
    exercises && (
      <UsageMonitoringGraph
        totalCounter={false}
        deviceFilter={false}
        header={t('goals:usage_tab.grip.exercises_progress', 'Exercises performed progress')}
        initialFilters={{
          period: PeriodEnum.day
        }}
        onFilterChange={({ exercise }) => setSelectedExercise(exercise)}
        graphHeight={'600px'}
        graphDataSource={[]}
        exercises={exercises}
        exerciseFilter
        showExplanation={false}
        GraphComponent={renderExercisesGraph}
        chartType='exercises-performed'
      />
    )
  );
};

const canDeleteGoal = (goal: GoalEntryPayload) => {
  const timeNow = new Date();
  const dateNow = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate()).getTime();
  const startTime = new Date(goal.start_date).getTime();
  const endTime = new Date(goal.end_date);
  const endTimeAdjusted = endTime.setDate(endTime.getDate() + 1);

  return dateNow < startTime || dateNow > endTimeAdjusted || goal.active !== 1;
};

const goalStatus = (goal: GoalEntryPayload) => {
  const timeNow = new Date();
  const dateNow = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate()).getTime();
  const startTime = new Date(goal.start_date).getTime();
  const endTime = new Date(goal.end_date);
  const endTimeAdjusted = endTime.setDate(endTime.getDate() + 1);

  if (dateNow < startTime) {
    if (goal.active === 0) return GoalsStatus.inactive;

    return GoalsStatus.awaiting;
  }

  if (dateNow >= startTime && dateNow < endTimeAdjusted) {
    if (goal.active === 0) return GoalsStatus.inactive;

    return GoalsStatus.inProgress;
  }

  return GoalsStatus.finished;
};

const canToggleActivity = (goal: GoalEntryPayload) => {
  const status = goalStatus(goal);
  const timeNow = new Date();
  const dateNow = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate()).getTime();
  const startTime = new Date(goal.start_date).getTime();
  return (
    status === GoalsStatus.awaiting ||
    (status === GoalsStatus.inactive && dateNow < startTime) ||
    status === GoalsStatus.inProgress
  );
};

const GoalUsageTab = ({ userId, initialGoal }: { userId: number; initialGoal?: number }) => {
  const { result, isLoading, refetch } = useUserGoalsInfinite({
    userId
  });
  const { t } = useTranslation('goals');
  const [data, setData] = useState<any>(null);
  const [selectedGoal, setSelectedGoal] = useState<GoalEntryPayload>();
  const queryParamsDevices: DevicesQueryParams = {
    amputee: String(userId),
    extend: [DeviceExtendOptions.model, DeviceExtendOptions.amputee]
  };
  const [initialGoalLoaded, setInitialGoalLoaded] = useState<boolean>(false);
  const { result: devices } = useDevicesList(queryParamsDevices);
  const { mutateAsync: deleteGoal, isLoading: isLoadingDeleteGoal } = useUserGoalDelete();
  const { mutateAsync: updateGoal, isLoading: isLoadingUpdateGoal } = useUserGoalUpdate();
  const {
    isOpen: isModalOpen,
    handleOpen: handleDeleteModalOpen,
    handleClose: handleDeleteModalClose
  } = useModal();
  const {
    isOpen: isModalToggleOpen,
    handleOpen: handleToggleModalOpen,
    handleClose: handleToggleModalClose
  } = useModal();

  const state = useUiStore((state) => state);
  // @ts-ignore
  const addGoalModal = useUiStore((state) => state.modals?.addGoalModal);
  const prevAddGoalModal = useRef(addGoalModal);

  useEffect(() => {
    if (prevAddGoalModal.current && !addGoalModal) {
      refetch();
    }
    prevAddGoalModal.current = addGoalModal;
  }, [addGoalModal]);

  useEffect(() => {
    if (result && !isLoading) {
      const formattedResult = result
        .map((item: any) => ({
          ...item,
          start_date: dayjs(item.start_date).format('DD MMM YYYY'),
          end_date: dayjs(item.end_date).format('DD MMM YYYY')
        }))
        .sort((goalA: any, goalB: any) => (goalA.created_at - goalB.created_at > 0 ? 1 : -1));

      setData(formattedResult);
    }
  }, [isLoading, JSON.stringify(result)]);

  useEffect(() => {
    if (initialGoal && data && !initialGoalLoaded) {
      setInitialGoalLoaded(true);
      expandChange(initialGoal);
    }
  }, [initialGoal, JSON.stringify(data)]);

  useEffect(() => {
    if (initialGoalLoaded) {
      const element = document.getElementById(`goal-${initialGoal}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [initialGoalLoaded, initialGoal]);

  const expandChange = (goalId: number) => {
    setData((prev: any) =>
      prev.map((item: any) => {
        let expanded = item.expanded;
        if (item.id === goalId) {
          expanded = !item.expanded;
        }
        return { ...item, expanded };
      })
    );
  };

  const { openModal } = useUiStore((state: any) => ({
    openModal: state.openModal
  }));

  const handleEdit = (goal: GoalEntryPayload, isView: boolean = false) => {
    const startDate = dayjs(goal.start_date);
    const endDate = dayjs(goal.end_date);
    const period = endDate.diff(startDate, 'day') + 1;
    const goalFormatted = formatGoal(goal.conditions!);

    openModal(MODALS.editGoalModal, {
      initialValues: { start_date: new Date(goal.start_date), period, ...goalFormatted },
      userId,
      goalId: goal.id,
      conditions: goal.conditions,
      isView
    });
  };

  const toggleGoalActivity = async (e: GoalEntryPayload) => {
    try {
      await updateGoal({
        userId: e.amputee_id,
        goalId: e.id,
        active: Number(e.active) ? 0 : 1
      });
      NotificationFactory.successNotification(
        t('notifications:usage_tab.grid.goal_update', 'Goal updated')
      );
    } catch (e) {
      console.log(e);
    }
  };

  const deleteGoalModal = async () => {
    try {
      if (selectedGoal) {
        await deleteGoal({ userId: selectedGoal.amputee_id, goalId: selectedGoal.id });
        NotificationFactory.successNotification(
          t('notifications:usage_tab.grid.goal_delete', 'Goal deleted')
        );
        handleDeleteModalClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleToggleActivityGoal = async (e: GoalEntryPayload) => {
    if (goalStatus(e) !== GoalsStatus.inProgress) {
      toggleGoalActivity(e);
      return;
    }

    setSelectedGoal(e);
    handleToggleModalOpen();
  };

  const handleDeleteGoal = async (e: GoalEntryPayload) => {
    setSelectedGoal(e);
    handleDeleteModalOpen();
  };

  const renderDetailComponent = useCallback(
    (e: any) => <DetailComponent goal={e.dataItem} devices={devices} />,
    [devices]
  );

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      {Boolean(isModalOpen && selectedGoal) && (
        <ConfirmDeleteModal
          handleClose={handleDeleteModalClose}
          handleAccept={deleteGoalModal}
          isLoading={isLoadingDeleteGoal}
          message={
            <ModalMessageDelete
              id={selectedGoal?.id}
              text={t('goals:usage_tab.grid.want_delete_goal', 'Do you want to delete goal')}
            />
          }
        />
      )}
      {Boolean(isModalToggleOpen && selectedGoal) && (
        <ConfirmDeleteModal
          handleClose={handleToggleModalClose}
          handleAccept={async () => {
            if (!selectedGoal) return;
            await toggleGoalActivity(selectedGoal);
            handleToggleModalClose();
          }}
          isLoading={isLoadingUpdateGoal}
          message={t(
            'goals:usage_tab.grid.want_turn_off_goal',
            'Are you sure you want to turn off this goal? Since the goal has already started, it will not be possible to turn it on again.'
          )}
          title={t('goals:usage_tab.grig.turn_off_goal', 'Turn off goal')}
          acceptMessage={t('goals:usage_tab.grid.turn_off', 'Yes, turn off')}
        />
      )}
      <Grid
        data={data}
        detail={renderDetailComponent}
        expandField='expanded'
        onExpandChange={(e) => expandChange(e.dataItem.id)}>
        <Column field='start_date' title={t('goals:usage_tab.grid.start_date', 'Start date')} />
        <Column field='end_date' title={t('goals:usage_tab.grid.end_date', 'End date')} />
        <Column
          field='active'
          title={t('goals:usage_tab.grid.active', 'Active')}
          cell={(e: any) => (
            <td>
              <div style={{ display: 'flex' }}>
                {(() => {
                  const status = goalsStatusMap.get(goalStatus(e.dataItem));
                  let chipColor;
                  switch (status) {
                    case goalsStatusMap.get(GoalsStatus.inactive):
                      chipColor = '#d32f2f';
                      break;
                    case goalsStatusMap.get(GoalsStatus.inProgress):
                      chipColor = '#33499C';
                      break;
                    case goalsStatusMap.get(GoalsStatus.finished):
                      chipColor = '#349c33';
                      break;
                    case goalsStatusMap.get(GoalsStatus.awaiting):
                      chipColor = '#000000';
                      break;
                    default:
                      chipColor = '#000000';
                  }
                  return (
                    <Chip
                      style={{
                        color: chipColor
                      }}
                      label={status}
                    />
                  );
                })()}
              </div>
            </td>
          )}
        />
        <Column
          width={getTableSize(4)}
          title={t('goals:usage_tab.grid.actions', 'Actions')}
          cell={(e: any) => (
            <ActionButtonsWrapper id={`goal-${e.dataItem.id}`}>
              <CustomButton
                Icon={LineChartUpSvg}
                color='light'
                tooltip={t('goals:usage_tab.grid.actions.graphs', 'View Graphs')}
                onClick={() => expandChange(e.dataItem.id)}
              />
              <CustomButton
                color='light'
                Icon={LeadingSwapSvg}
                tooltip={t('goals:usage_tab.grid.actions.details', 'View Details')}
                onClick={() => handleEdit(e.dataItem, true)}
              />
              {canToggleActivity(e.dataItem) && (
                <CustomButton
                  color='light'
                  Icon={e.dataItem.active ? LockOpenSVG : LockSVG}
                  tooltip={
                    e.dataItem.active
                      ? t('goals:usage_tab.grid.actions.disabled', 'Editing Disabled')
                      : t('goals:usage_tab.grid.actions.enabled', 'Editing Enabled')
                  }
                  onClick={() => handleToggleActivityGoal(e.dataItem)}
                />
              )}
              {canDeleteGoal(e.dataItem) && (
                <CustomButton
                  color='light'
                  Icon={TrashSvg}
                  tooltip={t('goals:usage_tab.grid.actions.delete', 'Delete goal')}
                  onClick={() => handleDeleteGoal(e.dataItem)}
                />
              )}
            </ActionButtonsWrapper>
          )}
        />
      </Grid>
    </>
  );
};

export default GoalUsageTab;
