import { useGripLabel } from 'adp-panel/hooks/useGripLabel';
import { getStandardGripIds } from 'adp-panel/utils/grips';

export type TimeGridUsageData = {
  id: string;
  label: string;
  // color: string;
  'Grip count': number;
  Percentage: string;
  description: string;
};

type GripTimeData = {
  device_id: number;
  time: number;
  grip: number;
  percentage_rounded: string;
  group_by: number;
  instances: number;
  percentage: string;
  Percentage: string;
};

type GripUsageData = {
  group_by: number;
  instances: number;
  time: number;
  percentage: string;
  'Grip count': number;
  'Grip switches': number;
  'Time spent': number;
  Percentage: string;
  percentage_rounded: string;
  grip: number;
  label_name: string;
};

export const useGripTimeMapper = (deviceId: number) => {
  const { getGripLabel } = useGripLabel(deviceId);

  const mapTotalGripData = (items: any) => {
    const baseData = getStandardGripIds().map(
      (gripId): GripUsageData => ({
        grip: gripId,
        percentage_rounded: '0.00',
        group_by: gripId,
        instances: 0,
        time: 0,
        percentage: '0.00',
        'Grip count': 0,
        'Grip switches': 0,
        'Time spent': 0,
        Percentage: '0.00',
        label_name: getGripLabel(gripId)
      })
    );

    if (!items?.length) {
      return baseData;
    }

    const existingDataMap = new Map<number, GripUsageData>(
      items.map((item: GripUsageData) => [Number(item.group_by), item])
    );

    const mappedData = baseData.map((baseItem) => {
      const existingItem = existingDataMap.get(baseItem.group_by);
      if (!existingItem) return baseItem;

      return {
        ...baseItem,
        instances: existingItem.instances || 0,
        time: existingItem.time || 0,
        percentage: existingItem.percentage || '0.00',
        'Grip count': existingItem.instances || 0,
        'Grip switches': existingItem.instances || 0,
        'Time spent': existingItem.time ? Math.floor(existingItem.time / 60) : 0,
        Percentage: existingItem.percentage
          ? parseFloat(existingItem.percentage).toFixed(2)
          : '0.00',
        label_name: getGripLabel(Number(baseItem.group_by))
      };
    });

    items.forEach((item: GripUsageData) => {
      const gripId = Number(item.group_by);
      if (!baseData.some((baseItem) => baseItem.group_by === gripId)) {
        mappedData.push({
          grip: gripId,
          group_by: gripId,
          instances: item.instances || 0,
          time: item.time || 0,
          percentage: item.percentage || '0.00',
          percentage_rounded: item.percentage_rounded || '0.00',
          'Grip count': item.instances || 0,
          'Grip switches': item.instances || 0,
          'Time spent': item.time ? Math.floor(item.time / 60) : 0,
          Percentage: item.percentage ? parseFloat(item.percentage).toFixed(2) : '0.00',
          label_name: getGripLabel(gripId)
        });
      }
    });

    return mappedData;
  };

  const mapGripTimeData = (items: any): TimeGridUsageData[] => {
    if (!items?.length) {
      return [];
    }

    return items.map((item: GripTimeData) => ({
      id: getGripLabel(Number(item.group_by)),
      label: getGripLabel(Number(item.group_by)),
      instances: item.instances || 0,
      'Grip count': item.instances || 0,
      'Grip switches': item.instances || 0,
      'Time spent': item.time ? Math.floor(item.time / 60) : 0,
      Percentage: item.percentage ? parseFloat(item.percentage).toFixed(2) : '0.00',
      description: `Time spent in ${getGripLabel(Number(item.group_by))} grip`
    }));
  };

  const mapHourlyGripData = (items: any) => {
    const hourlyReport: any = [];
    const itemsByHour = items.reduce((prev: any, next: any) => {
      if (prev[Number(next['group_by'])] !== undefined) {
        const prevElement = prev[Number(next['group_by'])];
        prev[parseInt(next['group_by'])] = {
          ...next,
          goal: parseInt(next?.goal) + parseInt(prevElement?.goal),
          instances: parseInt(next.instances) + parseInt(prevElement.instances),
          time: parseInt(next.time + prev.time)
        };
      } else {
        prev[parseInt(next['group_by'])] = next;
      }
      return prev;
    }, []);

    for (let i = 0; i <= 23; i++) {
      const item = itemsByHour[i];
      if (item !== undefined) {
        hourlyReport.push({
          ...item,
          group_by: `${String(item.group_by).padStart(2, '0')}-${String(
            Number(item.group_by) + 1
          ).padStart(2, '0')}`
        });
      } else {
        hourlyReport.push({
          group_by: `${String(i).padStart(2, '0')}-${String(i + 1).padStart(2, '0')}`,
          time: 0,
          instances: 0,
          percentage: 0,
          grip: null
        });
      }
    }
    const mapper = (items: any) =>
      items.map((item: any) => ({
        ...item,
        'Grip count': item.instances,
        'Grip switches': item.instances,
        'Time spent': item.time ? Math.floor(item.time / 60) : 0,
        Percentage: parseFloat(item.percentage).toFixed(2),
        label_name: getGripLabel(Number(item.group_by))
      }));

    return mapper(hourlyReport);
  };

  return {
    mapTotalGripData,
    mapGripTimeData,
    mapHourlyGripData
  };
};
