import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import {
  DateRangeType,
  PeriodType
} from 'adp-panel/components/DeviceUsageMonitoring/UsageMonitoringGraph';
import React, { createContext, useContext } from 'react';

export interface CommonFilters {
  selectedDevice?: DeviceEntry;
  dateRange?: DateRangeType;
  period?: PeriodType;
}

export const CommonFiltersContext = createContext<CommonFilters | undefined>(undefined);

export const useCommonFilters = () => {
  return useContext(CommonFiltersContext);
};

export const CommonFiltersProvider: React.FC<{
  children: React.ReactNode;
  value: CommonFilters;
}> = ({ children, value }) => {
  return <CommonFiltersContext.Provider value={value}>{children}</CommonFiltersContext.Provider>;
};
