import { Grips } from 'adp-panel/utils/definesLocal';
import { useGripLabel } from 'adp-panel/hooks/useGripLabel';

export const useGripOptions = (deviceId: number) => {
  const { getGripLabel } = useGripLabel(deviceId);

  const getStandardGripOptions = () => {
    const standardGrips = [
      Grips.kGripRestOpp,
      Grips.kGripPower,
      Grips.kGripTripodClosed,
      Grips.kGripTrigger,
      Grips.kGripPrecisionOpen,
      Grips.kGripRestNopp,
      Grips.kGripHook,
      Grips.kGripKey,
      Grips.kGripMouse,
      Grips.kGripFingerPoint,
      Grips.kGripTripodOpen,
      Grips.kGripPrecisionClosed,
      Grips.kGripFingerPointOpen,
      Grips.kGripCounting,
      Grips.kGripCustom1,
      Grips.kGripCustom2,
      Grips.kGripCustom3
    ];

    return standardGrips.map((gripId) => ({
      text: getGripLabel(gripId),
      id: gripId
    }));
  };

  return {
    getStandardGripOptions
  };
};

export type GripsType = {
  text: string;
  id: number;
};

export type GripsWithAllType = GripsType | { text: 'All'; id: 'all' };
