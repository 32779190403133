import { DeviceEntry } from '../../../api/devices/device.types';
import { RoleEnum, UserEntry } from '../../../api/users/users.types';

export interface Patient extends Omit<UserEntry, 'location'> {
  id: number;
  name: string;
  email: string;
  location?: {
    company?: {
      name: string;
      id: number;
    };
    name: string;
    id: number;
  };
  location_id?: number;
}

export interface DeviceTableRow {
  id: number;
  serial: string;
  model: string;
  mobileStatus: boolean;
  lastDumUpdate: {
    manual: string | null;
    background: string | null;
    fetching: string | null;
  };
}

export type RolesByName = Record<string, { name: RoleEnum }>;

export enum TabsEnum {
  DEVICE_USAGE = 0,
  GOALS = 1
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
