import { useEffect, useRef, useState } from 'react';

const MIN_FAIL_COUNT = 2;

const ping = ({ url, timeout }) => {
  return new Promise((resolve) => {
    const isOnline = () => resolve(true);
    const isOffline = () => resolve(false);

    const xhr = new XMLHttpRequest();

    xhr.onerror = isOffline;
    xhr.ontimeout = isOffline;
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 1) {
        if (xhr.status) {
          isOnline();
        } else {
          isOffline();
        }
      }
    };

    xhr.open('GET', url);
    xhr.timeout = timeout;
    xhr.send();
  });
};

const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const [buffer, setBuffer] = useState(0);
  const interval = useRef(null);

  useEffect(() => {
    if (!interval.current) {
      // @ts-ignore
      interval.current = setInterval(() => {
        ping({ url: 'https://httpbin.org/get', timeout: 10000 }).then((online) => {
          if (online) {
            setIsOnline(true);
            setBuffer(0);
          } else {
            setBuffer((prev) => (prev += 1));
          }
        });
      }, 10000);
    } else {
      clearInterval(interval.current);
      interval.current = null;
    }

    return () => {
      // @ts-ignore
      clearInterval(interval.current);
    };
  }, []);

  useEffect(() => {
    if (buffer >= MIN_FAIL_COUNT) {
      setIsOnline(false);
      setBuffer(0);
    }
  }, [buffer]);

  return isOnline;
};

export default useIsOnline;
