/* eslint-disable no-unused-vars */

import { GoalFrequencyType } from 'adp-panel/api/goals/goals.types';
import { RoleEnum } from '../api/users/users.types';
import { GroupEnum } from 'adp-panel/api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import { codes } from 'adp-panel/constants/countries';

/* eslint-disable no-unused-vars */

/* eslint-disable no-shadow */

export enum InputSites {
  kSingleElectrode = 0,
  kDualDirectElectrodes = 1,
  kDualInvertedElectrodes = 2
}

export enum SpeedControlStrategies {
  kThreshold = 0,
  kProportional = 1
}

export enum GripSwitchingModes {
  kCoContraction = 0,
  kOpenOpen = 1,
  kSingleGripSwitching = 2,
  kHoldOpen = 3
}

export enum InputOptions {
  kEMG = 0,
  kLinearTransducer = 1
}

export enum ControlModes {
  kGripPairs = 0,
  kGripSequence = 1,
  kMorseCode = 2
}

export enum InputDevices {
  kInputOptionElectrodes = 0,
  kInputOptionPatRec = 1
}

// eslint-disable-next-line no-shadow
export enum Grips {
  kGripRestOpp = 0,
  kGripPower = 1,
  kGripTripodClosed = 2,
  kGripTrigger = 3,
  kGripPrecisionOpen = 4,
  kGripRestNopp = 5,
  kGripHook = 6,
  kGripKey = 7,
  kGripMouse = 8,
  kGripFingerPoint = 9,
  kGripCamera = 10,
  kGripTripodOpen = 11,
  kGripPrecisionClosed = 12,
  kGripFingerPointOpen = 13,
  kGripCounting = 14,
  kGripCustom1 = 16,
  kGripCustom2 = 17,
  kGripCustom3 = 18,
  kGripTypeUnknown = 255
}

export enum SingleElectrodeMode {
  kFastRising = 0,
  kAlternating = 1
}

export enum StartOnStartup {
  kWaitForFirstCoContraction = 0,
  kNotWaitForFirstCoContraction = 1
}

const gripsTuples: any = [
  [Grips.kGripPower, { grip: 'Power', option: null }],
  [Grips.kGripTripodOpen, { grip: 'Tripod', option: 'open' }],
  [Grips.kGripTripodClosed, { grip: 'Tripod', option: 'closed' }],
  [Grips.kGripTrigger, { grip: 'Trigger', option: null }],
  [Grips.kGripPrecisionOpen, { grip: 'Precision', option: 'open' }],
  [Grips.kGripPrecisionClosed, { grip: 'Precision', option: 'closed' }],
  [Grips.kGripHook, { grip: 'Hook', option: null }],
  [Grips.kGripKey, { grip: 'Key', option: null }],
  [Grips.kGripFingerPoint, { grip: 'FingerPoint', option: null }],
  [Grips.kGripMouse, { grip: 'Mouse', option: null }],
  [Grips.kGripCamera, { grip: 'Camera', option: null }],
  [Grips.kGripRestOpp, { grip: 'Rest opp', option: null }],
  [Grips.kGripRestNopp, { grip: 'Rest non-opp', option: null }],
  [Grips.kGripFingerPointOpen, { grip: 'Active Index', option: null }],
  [Grips.kGripCounting, { grip: 'Counting', option: null }]
];

export const ConfigSettingsNamesTuples: any = [
  ['gripPairsConfig', 'Grips pairs'],
  ['inputSite', 'Electrode input configuration'],
  ['inputDevice', 'Input device'],
  ['controlMode', 'Control mode'],
  ['speedControlStrategy', 'Speed control strategy'],
  ['gripSwitchingMode', 'Grip switching mode'],
  ['gripSequentialConfig', 'Grips sequential'],
  ['emgThresholds', 'EMG thresholds %'],
  ['fingerStrength', 'Finger strength'],
  ['autoGrasp', 'Autograsp'],
  ['emgSpike', 'EMG spikes'],
  ['holdOpen', 'Hold open'],
  ['softGrip', 'Soft grip'],
  ['emgGains', 'EMG gains'],
  ['pulseTimings', 'Pulse timings'],
  ['coContractionTimings', 'Co-contraction timings'],
  ['gripsPositions', 'Grip positions'],
  ['batteryBeep', 'Low battery beep'],
  ['singleElectrodeMode', 'Single electrode mode'],
  ['singleElectrodeModeSettings', 'Electrode settings'],
  ['freezeModeEmg', 'Freeze mode EMG'],
  ['generalHandSettings', 'General hand settings'],
  ['followingGrip', 'Following grip'],
  ['freezeModeEmgSettings', 'Freeze mode emg settings'],
  ['buzzingVolumeSettings', 'Buzzing volume'],
  ['userFeedbackType', 'Feedback type']
];

const singleElectrodeModeTuples: any = [
  [SingleElectrodeMode.kFastRising, 'Slope'],
  [SingleElectrodeMode.kAlternating, 'Alternating']
];

const inputDeviceTuples: any = [
  [InputDevices.kInputOptionElectrodes, 'EMG'],
  [InputDevices.kInputOptionPatRec, 'Coapt']
];

const useFeedbackTypeTuples: [0, string][] = [[0, 'Finger buzzer']];

const userFeedbackTypePcbV4Tuples: [1 | 2, string][] = [
  [1, 'External buzzer'],
  [2, 'Vibration']
];

const allUserFeedbackTypeTuples = [...useFeedbackTypeTuples, ...userFeedbackTypePcbV4Tuples];

const startOnStartupTuples: any = [
  [StartOnStartup.kNotWaitForFirstCoContraction, 'Off'],
  [StartOnStartup.kWaitForFirstCoContraction, 'On']
];

const freezeModeTuples: [0 | 1 | 3, string][] = [
  [0, 'Off'],
  [1, 'On'],
  [3, 'On']
];

const gripsOptionsTuples: [Grips, string][] = [
  [Grips.kGripPower, 'Power'],
  [Grips.kGripTripodOpen, 'Tripod Open'],
  [Grips.kGripTripodClosed, 'Tripod Closed'],
  [Grips.kGripTrigger, 'Trigger'],
  [Grips.kGripPrecisionOpen, 'Precision Open'],
  [Grips.kGripPrecisionClosed, 'Precision Closed'],
  [Grips.kGripCamera, 'Camera'],
  [Grips.kGripHook, 'Hook'],
  [Grips.kGripKey, 'Key'],
  [Grips.kGripFingerPoint, 'Finger Point'],
  [Grips.kGripMouse, 'Mouse'],
  [Grips.kGripRestOpp, 'Rest opp'],
  [Grips.kGripRestNopp, 'Rest non-opp'],
  [Grips.kGripFingerPointOpen, 'Active Index'],
  [Grips.kGripCounting, 'Counting'],
  [Grips.kGripCustom1, 'Custom 1'],
  [Grips.kGripCustom2, 'Custom 2'],
  [Grips.kGripCustom3, 'Custom 3'],
  [Grips.kGripTypeUnknown, 'Disabled']
];

const gripsOptionsTuplesWithTranslationKey: [Grips, string][] = [
  [Grips.kGripPower, 'configurator:constants.grips.power'],
  [Grips.kGripTripodOpen, 'configurator:constants.grips.tripod_open'],
  [Grips.kGripTripodClosed, 'configurator:constants.grips.tripod_closed'],
  [Grips.kGripTrigger, 'configurator:constants.grips.trigger'],
  [Grips.kGripPrecisionOpen, 'configurator:constants.grips.precision_open'],
  [Grips.kGripPrecisionClosed, 'configurator:constants.grips.precision_closed'],
  [Grips.kGripCamera, 'configurator:constants.grips.camera'],
  [Grips.kGripHook, 'configurator:constants.grips.hook'],
  [Grips.kGripKey, 'configurator:constants.grips.key'],
  [Grips.kGripFingerPoint, 'configurator:constants.grips.finger_point'],
  [Grips.kGripMouse, 'configurator:constants.grips.mouse'],
  [Grips.kGripRestOpp, 'configurator:constants.grips.rest_opp'],
  [Grips.kGripRestNopp, 'configurator:constants.grips.open_palm'],
  [Grips.kGripFingerPointOpen, 'configurator:constants.grips.active_index'],
  [Grips.kGripCounting, 'configurator:constants.grips.counting'],
  [Grips.kGripCustom1, 'configurator:constants.grips.custom1'],
  [Grips.kGripCustom2, 'configurator:constants.grips.custom2'],
  [Grips.kGripCustom3, 'configurator:constants.grips.custom3'],
  [Grips.kGripTypeUnknown, 'configurator:constants.grips.disabled']
];

const inputOptionTuples: any = [
  [InputOptions.kEMG, 'EMG'],
  [InputOptions.kLinearTransducer, 'Linear Tranducer']
];

const inputSiteTuples: any = [
  [InputSites.kSingleElectrode, 'Single'],
  [InputSites.kDualDirectElectrodes, 'Dual direct'],
  [InputSites.kDualInvertedElectrodes, 'Dual Inverted']
];

const speedControlTuples: any = [
  [SpeedControlStrategies.kThreshold, 'One speed'],
  [SpeedControlStrategies.kProportional, 'Proportional']
];

const gripSwitchingTuples: any = [
  [GripSwitchingModes.kCoContraction, 'Co-contraction'],
  [GripSwitchingModes.kOpenOpen, 'Open-open'],
  [GripSwitchingModes.kSingleGripSwitching, 'Single electrode'],
  [GripSwitchingModes.kHoldOpen, 'Hold-open']
];

const controlModeTuples: any = [
  [ControlModes.kGripPairs, 'Pairing mode'],
  [ControlModes.kGripSequence, 'Sequential mode'],
  [ControlModes.kMorseCode, 'Morse code']
];

const genericSwitchingTuples: any = [
  [0, 'Off'],
  [1, 'On']
];

export const Fingers = {
  Thumb: 0,
  Index: 1,
  Middle: 2,
  Ring: 3,
  Pinky: 4
};

const SettingsNameTuples: any = [
  ['chooseGripsHistory', 'Choose Grips'],
  ['emgSettingsHistory', 'EMG settings'],
  ['emgThresholdsHistory', 'EMG settings'],
  ['gripPairsConfigHistory', 'Grips configuration'],
  ['gripsConfigurationHistory', 'Grips configuration'],
  ['gripSequentialConfigHistory', 'Grips configuration'],
  ['prosthesisSettingsHistory', 'Prosthesis Settings']
];

const rolesTuples: any = [
  [RoleEnum.clinician, 'Clinician'],
  [RoleEnum.clinicianSupport, 'Allied Health'],
  [RoleEnum.clinicAdmin, 'Clinic Admin'],
  [RoleEnum.amputee, 'Patient'],
  [RoleEnum.superAdmin, 'Super Admin']
];

const rolesPluralTuples: any = [
  [RoleEnum.clinician, 'Clinicians'],
  [RoleEnum.clinicianSupport, 'Allied Health'],
  [RoleEnum.clinicAdmin, 'Clinic Admins'],
  [RoleEnum.amputee, 'Patients']
];

const timePeriodsTuples: any = [
  [GoalFrequencyType.d, 'Daily'],
  [GoalFrequencyType.w, 'Weekly'],
  [GoalFrequencyType.m, 'Month'],
  [GoalFrequencyType.a, 'Goal time frame']
];

const timeFrequenciesTuples: any = [
  [GoalFrequencyType.d, GroupEnum.day],
  [GoalFrequencyType.w, GroupEnum.week],
  [GoalFrequencyType.m, GroupEnum.month],
  [GoalFrequencyType.a, GroupEnum.day]
];

// eslint-disable-next-line no-unused-vars
export const inputOptionsMap: any = new Map(inputOptionTuples);
export const inputSiteMap: any = new Map(inputSiteTuples);
export const speedControlMap: any = new Map(speedControlTuples);
export const gripSwitchingMap: any = new Map(gripSwitchingTuples);
export const controlModeMap: any = new Map(controlModeTuples);
export const genericSwitchingMap: any = new Map(genericSwitchingTuples);
export const timePeriodsMap: any = new Map(timePeriodsTuples);
export const timeFrequenciesMap: any = new Map(timeFrequenciesTuples);

export const inputOptionsReversedMap = new Map(
  Array.from(inputOptionsMap, (a: any) => a.reverse())
);
export const inputSiteReversedMap = new Map(Array.from(inputSiteMap, (a: any) => a.reverse()));
export const speedControlReversedMap = new Map(
  Array.from(speedControlMap, (a: any) => a.reverse())
);
export const gripSwitchingReversedMap = new Map(
  Array.from(gripSwitchingMap, (a: any) => a.reverse())
);
export const controlModeReversedMap = new Map(Array.from(controlModeMap, (a: any) => a.reverse()));
export const genericSwitchingReversedMap = new Map(
  Array.from(genericSwitchingMap, (a: any) => a.reverse())
);

export const gripsGroupsMap = new Map(gripsTuples);
export const gripsGroupsOptionsMap = new Map<Grips, string>(gripsOptionsTuples);
export const gripsGroupsOptionsReversedMap = new Map(
  Array.from(gripsGroupsOptionsMap, (a: any) => a.reverse())
);

export const gripsGroupsOptionsWithTranslationKeyMap = new Map<Grips, string>(
  gripsOptionsTuplesWithTranslationKey
);
export const gripsGroupsOptionsWithTranslationKeyReversedMap = new Map(
  Array.from(gripsGroupsOptionsWithTranslationKeyMap, (a: any) => a.reverse())
);
export const historySettingsNameMap = new Map(SettingsNameTuples);
export const historySettingsNameReversedMap = new Map(
  Array.from(historySettingsNameMap, (a: any) => a.reverse())
);

export const rolesNamesMap = new Map<any, string>(rolesTuples);
export const rolesPluralNamesMap = new Map<any, string>(rolesPluralTuples);
export const rolesNamesReversedMap = new Map(Array.from(rolesNamesMap, (a: any) => a.reverse()));

export const timePeriodsReversedMap = new Map(Array.from(timePeriodsMap, (a: any) => a.reverse()));

const dialCodeTuples: [string, string][] = codes.map((dial) => [dial.code, dial.country]);
export const countryCodeMap = new Map<string, string>(dialCodeTuples);
const dialCodeTuplesReversed: [string, string][] = codes.map((dial) => [dial.country, dial.code]);
export const countryCodeReversedMap = new Map<string, string>(dialCodeTuplesReversed);

export const gripsIds = Object.values(Grips).filter((v) => !isNaN(Number(v)) && v !== 255);
export const gripsNames = gripsIds.map((gripId) => gripsGroupsOptionsMap.get(Number(gripId)));
export const configSettingsNamesMap: any = new Map(ConfigSettingsNamesTuples);
export const singleElectrodeModeMap: any = new Map(singleElectrodeModeTuples);
export const inputDeviceMap: any = new Map(inputDeviceTuples);
export const allUserFeedbackTypeMap: Map<number, string> = new Map(allUserFeedbackTypeTuples);
export const startOnStartupMap: any = new Map(startOnStartupTuples);
export const freezeModeMap: Map<number, string> = new Map(freezeModeTuples);
